import Image from "next/image"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ArrowRight } from "react-bootstrap-icons"
import background from "@/public/header-background.svg"
import { Button } from "@mui/material"
import translate from "@/utils/translation"
import { useSession } from "next-auth/react"
import { Context } from "@/context/state"
import Script from "next/script"
import posthog from "posthog-js"

const Homebanner = () => {
  const router = useRouter()
  const { data: session } = useSession()
  const { dispatch, state } = useContext(Context)

  return (
    <Wrapper className="css-16h2cue">
      <div className="container">
        <div className="row m-0">
          <div className="p-md-0 col-md-6 col-12 textWrapper order-2 order-md-1">
            <h1 className={"mt-md-5 title"}>
              {translate("homepage_header_subtitle_1_test")}{" "}
              <span className="highlight">
                {translate("homepage_header_subtitle_2_test")}
              </span>{" "}
              {translate("homepage_header_subtitle_3_test")}
            </h1>

            <p className="mt-3 mb-3 mt-md-4 subtitle">
              {translate("homepage_discover_description_1_test") + " "}
              <strong>{translate("homepage_header_description_2_test")}</strong>
              {" " + translate("homepage_header_description_3_test")}
            </p>
            <div className="mt-3 mt-md-4 sign-up d-flex flex-column flex-md-row gap-3">
              <Button
                variant="contained"
                className="header-button w-100 rounded-pill d-flex justify-content-center align-items-center"
                onClick={() => {
                  if (session) {
                    router.push("/listings")
                  } else {
                    dispatch({ type: "SET_LOGIN_MODAL" })
                  }
                }}
              >
                <p className="mb-0">
                  {translate("homepage_header_primary_button_test")}
                </p>
                <ArrowRight className="ms-1" />
              </Button>

              <Button
                variant="outlined"
                className="header-button d-none d-md-block w-100 ms-md-3 mt-3 mt-md-0 rounded-pill"
                onClick={() => router.push("#how-it-works")}
              >
                <p>{translate("homepage_header_secondary_button")}</p>
              </Button>
            </div>
            <div className="mt-4 trustpilot-container">
              <a
                href={`https://trustpilot.com/review/valvestcapital.com?languages=${
                  router?.locale ? router.locale : "en"
                }`}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="trustpilot"
              >
                <div className="trustpilot-content">
                  <p className="trustpilot-text">
                    {translate("homepage_header_trustpilot")}
                  </p>
                  <div className="stars">
                    <img
                      className="stars"
                      alt="Rated 4.7 of 5 stars"
                      src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.5.svg"
                    />
                  </div>
                  <Image
                    src="/trustpilot.svg"
                    alt="Trustpilot"
                    width={100}
                    height={24}
                    className="trustpilot-logo"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="d-block d-md-none col-md-6 text-center col-12 position-relative order-1 order-md-2">
            <img
              className="main"
              src="/header-mobile.webp"
              alt="Header Mobile"
            />
          </div>
          <div className="d-none d-md-block col-md-6 text-center col-12 position-relative order-1 order-md-2">
            <img
              className="main"
              src="/header-desktop.png"
              alt="Header Desktop"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Homebanner

const Wrapper = styled.div`
  width: 100%;
  min-height: 580px;
  background-color: white;
  position: relative;

  @media (max-width: 768px) {
    min-height: 400px;
    padding-top: 0;
  }

  .main {
    position: relative;
    z-index: 1;
    width: 100%;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: -2.5rem;
      margin-left: -1rem;
    }
  }

  .showcase {
    color: #fb8500;
    text-decoration: none;
    font-size: 0.875rem;
  }

  .sign-up {
    display: flex;
    gap: 1rem;

    .MuiInputBase-root {
      max-height: 45px;
      height: 45px;
      border-radius: 20px;
      width: 240px;
      background: white;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .header-button {
      max-height: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;

      p {
        font-weight: 500;
        margin: 0;
        margin-right: 0.5rem;
      }

      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
      }
    }

    max-width: 400px;
  }

  h1 {
    font-size: 2.6rem;
    line-height: 3.6rem;
    max-width: 500px;
    font-weight: 700;

    &.title-en {
      font-size: 2.9rem;
    }

    @media (max-width: 992px) {
      margin-top: -1rem;

      &.title-en {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }

    @media (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 3rem;

      &.title-en {
        font-size: 2.6rem;
      }
    }

    @media (max-width: 550px) {
      font-size: 1.8rem;
      line-height: 2.6rem;

      &.title-en {
        font-size: 2.3rem !important;
      }
    }

    @media (max-width: 400px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
      &.title-en {
        font-size: 1.8rem;
      }
    }
  }

  ul {
    margin: 2rem 0 1rem -1.5rem;
    list-style: none;
    font-size: 1rem;
    font-weight: 300;

    li {
      margin-bottom: 0.4rem;
      color: black;
    }
  }

  .prospectus p {
    margin: 1rem 0 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: #585858;
    text-decoration: underline;
  }

  .highlight {
    color: #fb8500;
    opacity: 1;
  }

  :before {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    z-index: 1;
    background-image: url(${background.src});
    background-repeat: no-repeat;
  }

  .sell-button {
    margin-left: 1rem;
    border: 1px solid black;
    color: black;

    :hover {
      background-color: black;
      color: white;
      border: 1px solid black;
    }
  }

  .trustpilot-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 1rem;
    }

    .trustpilot {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #17233b;

      .trustpilot-content {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .trustpilot-text {
          font-weight: 400;
          font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
          margin: 0;
          margin-right: 0.25rem;
          margin-top: 0.25rem;
        }

        .stars {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          margin-top: 0.15rem;
          width: 100px;
        }

        .review-count {
          font-weight: 300;
          margin-top: 0.25rem;
          margin-left: 0.25rem;
          font-family: "HelveticaNeue-Light", "Helvetica Neue Light";

          .amount {
            font-family: "Helvetica Neue";
            font-weight: 500;
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  .textWrapper {
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    z-index: 1;

    @media (max-width: 768px) {
      margin-top: -4rem;
    }

    @media (max-width: 550px) {
      margin-top: -3rem;
      margin-bottom: 0 !important;

      p {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  .subtitle {
    max-width: 500px;
    font-size: 1.1rem;
    font-weight: 300;
    padding-bottom: 0.5rem;
    line-height: 1.6rem;

    @media (max-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.6rem;
    }

    @media (max-width: 550px) {
      font-size: 1rem;
    }

    @media (max-width: 400px) {
      font-size: 1.1rem;
      margin-top: 0.75rem !important;
    }
  }

  .topheader {
    font-size: 0.875rem;
    color: #fb8500;
    font-weight: 400;
    margin-bottom: 1.125rem;
  }

  @media (max-width: 400px) {
    margin-bottom: 3rem;
  }
`
