import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"
import Item from "@/components/listings/overview/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Button } from "@mui/material"

const ListingMobile = ({ session }) => {
  const { state, dispatch } = useContext(Context)
  const { listings } = state

  // Initialize Embla Carousel
  const [mainRef, emblaMain] = useEmblaCarousel({
    loop: false,
    skipSnaps: false,
    dragFree: true,
    align: "center",
  })

  const [selectedIndex, setSelectedIndex] = useState(0)

  // Synchronize Main Carousel Selection
  useEffect(() => {
    if (emblaMain) {
      const onSelect = () => {
        setSelectedIndex(emblaMain.selectedScrollSnap())
      }
      emblaMain.on("select", onSelect)

      // Initialize the selected index
      onSelect()

      // Cleanup event listeners on unmount
      return () => {
        emblaMain.off("select", onSelect)
      }
    }
  }, [emblaMain])

  if (!listings || listings.length === 0) return null

  return (
    <App className="d-md-none">
      <div className="ps-4 pe-4 pb-3 header">
        <h3>
          {translate("homepage_listings_recent")}
          <span className="highlight">
            {translate("homepage_listings_recent_highlight")}
          </span>
        </h3>
      </div>
      <SliderWrapper>
        <Embla className="main-slider">
          <Viewport ref={mainRef}>
            <Container>
              {listings.length > 0 ? (
                listings
                  ?.filter(
                    item =>
                      item.status === "investing" ||
                      item.status === "funded" ||
                      item.status === "soon"
                  )
                  .sort((a, b) => b.status.localeCompare(a.status))
                  .sort((a, b) => {
                    const statusOrder = { investing: 1, soon: 2, funded: 3 }
                    const statusComparison =
                      (statusOrder[a.status] || 4) -
                      (statusOrder[b.status] || 4)

                    if (statusComparison !== 0) return statusComparison

                    // If both listings are "funded", sort by fundedDate (newest first)
                    if (a.status === "funded" && b.status === "funded") {
                      return (
                        new Date(b.financials?.fundedDate || 0) -
                        new Date(a.financials?.fundedDate || 0)
                      )
                    }

                    // Sort by funding completion
                    const aFunded = a.financials?.fundPercent === 100
                    const bFunded = b.financials?.fundPercent === 100
                    if (aFunded !== bFunded) return bFunded - aFunded

                    // Then, sort by highest fund percentage
                    return (
                      (b.financials?.fundPercent || 0) -
                      (a.financials?.fundPercent || 0)
                    )
                  })
                  .splice(0, 4)
                  .map((listing, i, arr) => {
                    const isLast = i === arr.length - 1
                    return (
                      <SlideItem key={listing.uid} isFirst={i === 0}>
                        <Item listing={listing} session={session} />
                      </SlideItem>
                    )
                  })
              ) : (
                <LoadingContainer>Loading...</LoadingContainer>
              )}
            </Container>
          </Viewport>
        </Embla>
      </SliderWrapper>
    </App>
  )
}

export default ListingMobile

// Styled Components

const App = styled.div`
  padding: 3rem 0 3rem;

  h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .highlight {
    color: #ff8500;
  }

  @media (max-width: 768px) {
    h3 {
      margin-bottom: 0.5rem;
    }
  }
`

const SliderWrapper = styled.div`
  position: relative;
`

const Embla = styled.div`
  position: relative;
`

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  margin-left: -10px;
`
const SlideItem = styled.div`
  position: relative; // <- Important for .overlay to position correctly
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 325px;
  max-width: 325px;
  min-width: 325px;
  padding-left: 15px;
  padding-top: 0.5rem;

  &:first-child {
    padding-left: 25px;
  }

  @media (max-width: 768px), (max-width: 480px) {
    width: 90%;
  }

  .overlay {
    position: absolute;
    top: 10px;
    left: 15px;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: rgba(0, 0, 0, 0.3); // Overlay shade

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 8px;

    .view-all-btn {
      pointer-events: auto;
      cursor: pointer;

      .button {
        color: white;
        border: 2px solid white;
        border-radius: 20px;
      }
    }
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const Dot = styled.button`
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  padding: 0;
  border: none;
  background: none;
  margin: 0 6px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: ${props => (props.active ? "#000" : "#c4c4c4")};
    transition: fill 0.3s;
  }

  &:focus {
    outline: none;
  }

  &:hover svg {
    fill: #000;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }

  @media (max-width: 480px) {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
`

const DotSVG = () => (
  <svg viewBox="0 0 10 10" aria-hidden="true" focusable="false">
    <circle cx="5" cy="5" r="5" />
  </svg>
)
