import Item from "@/components/listings/overview/item"
import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Button, Fade } from "@mui/material"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState, useMemo } from "react"
import styled from "styled-components"

const Listings = ({ session }) => {
  const router = useRouter()
  const { state } = useContext(Context)
  const [fadeIn, setFadeIn] = useState(true)
  // This flag will prevent the fade animation from re-triggering
  const [hasAnimated, setHasAnimated] = useState(false)

  // After the initial fade, set hasAnimated to true
  useEffect(() => {
    const timeout = setTimeout(() => setHasAnimated(true), 500)
    return () => clearTimeout(timeout)
  }, [])

  const listingsToRender = useMemo(() => {
    if (!state.listings?.length) return []

    return state.listings
      .filter(item => ["investing", "funded", "soon"].includes(item.status))
      .sort((a, b) => {
        const statusOrder = { investing: 1, soon: 2, funded: 3 }
        const statusComparison =
          (statusOrder[a.status] || 4) - (statusOrder[b.status] || 4)

        if (statusComparison !== 0) return statusComparison

        if (a.status === "funded" && b.status === "funded") {
          return (
            new Date(b.financials?.fundedDate || 0) -
            new Date(a.financials?.fundedDate || 0)
          )
        }

        const aFunded = a.financials?.fundPercent === 100
        const bFunded = b.financials?.fundPercent === 100
        if (aFunded !== bFunded) return bFunded - aFunded

        return (
          (b.financials?.fundPercent || 0) - (a.financials?.fundPercent || 0)
        )
      })
  }, [state.listings])

  const SkeletonLoader = ({ length }) =>
    Array.from({ length }).map((_, i) => (
      <div
        className="col-lg-3 col-12 col-sm-6 col-md-4 mt-4 mt-md-2 p-md-2 pb-0 pt-0 skeleton"
        key={i}
      >
        <div className="card">
          <div className="skeleton-image full"></div>
          <div className="card-body">
            <div className="card-title">
              <div className="skeleton-line middle mt-4"></div>
              <div className="d-flex justify-content-between">
                <div className="skeleton-line short"></div>
              </div>
            </div>
            <hr className="m-0 p-0 mb-3" />
            <div className="card-title">
              <div className="d-flex justify-content-between">
                <div className="skeleton-line full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

  const ListingsRow = ({ listings, count, showOverlay }) =>
    listings.slice(0, count).map((listing, i, arr) => {
      // Define the content that might be wrapped in the Fade
      const content = (
        <div className="listing p-0 position-relative">
          <Item listing={listing} session={session} />
        </div>
      )

      // Only apply the fade animation if it hasn't already been played.
      return hasAnimated ? (
        content
      ) : (
        <Fade
          in={fadeIn}
          timeout={{ enter: 500, exit: 250 }}
          style={{ transitionDelay: `${i * 50}ms` }}
          key={`asi-${i}`}
        >
          {content}
        </Fade>
      )
    })

  return (
    <Wrapper className="d-none d-md-block">
      <div className="container">
        <div className="text-center header">
          <h3>
            {translate("homepage_listings_recent")}
            <span className="highlight">
              {translate("homepage_listings_recent_highlight")}
            </span>
          </h3>
        </div>

        <div className="d-none d-md-flex d-xl-none p-0 m-0 mt-md-4 gap-3">
          {listingsToRender.length > 0 ? (
            <ListingsRow
              listings={listingsToRender}
              count={3}
              showOverlay={false}
            />
          ) : (
            <SkeletonLoader length={3} />
          )}
        </div>

        <div className="d-none d-xl-flex p-0 m-0 mt-md-4 gap-3">
          {listingsToRender.length > 0 ? (
            <ListingsRow
              listings={listingsToRender}
              count={4}
              showOverlay={true}
            />
          ) : (
            <SkeletonLoader length={4} />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default React.memo(Listings)

const Wrapper = styled.div`
  padding: 5rem 0 3.5rem;

  hr {
    color: #c4bfbf;
  }

  .card-body {
    padding: 0em 1rem 0.5rem;
  }

  .skeleton-image {
    height: 200px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
  }

  .skeleton-line {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .skeleton-button {
    margin-top: 15px;
    height: 35px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 0;
  }

  .full {
    width: 100%;
  }

  .long {
    width: 80%;
  }

  .short {
    width: 30%;
  }

  .middle {
    width: 50%;
  }

  .listing {
    height: 100%;
    width: 100%;
  }

  .header {
    justify-content: center;
    margin: 0 auto;
    max-width: 450px;
    margin-bottom: 2.25rem;
  }

  .highlight {
    color: #ff8500;
  }

  .view-all-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 25%);
    z-index: 2;
    pointer-events: auto;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      color: rgba(255, 255, 255, 100%);
      border: 2px solid rgba(255, 255, 255, 100%);
      border-radius: 20px;
    }
  }

  @media (max-width: 800px) {
    .skeleton {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 768px) {
    .listing {
      margin: 0 auto;
    }

    h2 {
      margin-bottom: 0rem;
      color: #333;
    }
  }
`
