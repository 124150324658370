import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import Faq from "@/components/homepage/Faq/Faq"
import Performance from "@/components/homepage/performance/performance"
import CTA from "@/components/homepage/CTA/CTA"
import { useContext, useEffect, useState } from "react"
import { Context } from "@/context/state"
import Benefits from "@/components/homepage/benefits/benefits"
import Review from "@/components/homepage/reviews/Review"

import Homebanner from "@/components/homepage/banner/Homebanner"
import ProjectSelection from "@/components/homepage/projectSelection/projectSelection"
import Video from "@/components/homepage/video/Video"
import ListingMobile from "@/components/homepage/listings/ListingMobile"
import Listings from "@/components/homepage/listings/Listings"
import OnboardingBanner from "@/components/common/onboardingBanner/onboardingBanner"
import HomeCarousel from "@/components/homepage/banner/HomeCarousel"
import posthog from "posthog-js"

const Home = () => {
  const { state, dispatch } = useContext(Context)

  useEffect(() => {
    if (!state?.listings) {
      const fetchData = async () => {
        dispatch({ type: "SET_LOADING", payload: true })

        try {
          const response = await fetch("/api/listings")
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          const result = await response.json()

          dispatch({ type: "SET_LISTINGS", payload: result })
        } catch (err) {
          dispatch({ type: "SET_LISTINGS", payload: [] })
        } finally {
          dispatch({ type: "SET_LOADING", payload: false })
        }
      }

      fetchData()
    }
  }, [state.listings, dispatch])

  return (
    <>
      <HeaderNavigation />
      <OnboardingBanner />
      <Homebanner />
      <Performance />
      <Listings />
      <ListingMobile />
      <ProjectSelection />
      <Video />
      <Benefits />
      <Review />
      <Faq />
      <CTA />
    </>
  )
}

export default Home
