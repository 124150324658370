import translate from "@/utils/translation"
import { Button } from "@mui/material"
import { useRouter } from "next/router"
import TagManager from "react-gtm-module"
import React, { useContext } from "react"
import {
  GraphUp,
  ShieldCheck,
  ArrowRight,
  PieChart,
} from "react-bootstrap-icons"
import styled from "styled-components"
import { Context } from "@/context/state"
import { useSession } from "next-auth/react"

const Benefits = () => {
  const router = useRouter()
  const { dispatch } = useContext(Context)
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container p-0">
        <div className="benefits-row row m-0 mt-md-3 align-items-center">
          <div className="text-center text-md-center mb-md-5 mb-4">
            <h2>{translate("homepage_benefits_title")}</h2>
            <p className="description m-auto">
              {translate("homepage_benefits_subtitle")}
            </p>
          </div>
          <div className="col-12 col-md-6 order-2 order-md-1 d-flex justify-content-center">
            <div className="image">
              <img
                src="/daniel-phone-v2.webp"
                alt="Investment"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 order-3 order-md-2 mt-4 mt-md-0">
            <div className="row justify-content-center">
              {benefitsData.map((benefit, index) => (
                <div key={index} className="col-12 p-2">
                  <div className="item d-flex">
                    <div className="icon">{benefit.icon}</div>
                    <div className="content">
                      <h4>{translate(benefit.title)}</h4>
                      <p className="mb-0">{translate(benefit.text)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center text-md-start mt-4">
              <Button
                variant="contained"
                size="large"
                className="rounded-pill"
                onClick={() => {
                  session?.profile
                    ? router.push(`/listings/`)
                    : dispatch({ type: "SET_LOGIN_MODAL" })
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "homepage_howitworks_button",
                      user_id: session?.uid,
                    },
                  })
                }}
              >
                {translate("homepage_usps_button")}
                <ArrowRight className="ms-1" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const benefitsData = [
  {
    icon: <GraphUp size={40} />,
    title: "homepage_benefits_1_title",
    text: "homepage_benefits_1_text",
  },
  {
    icon: <ShieldCheck size={40} />,
    title: "homepage_benefits_3_title",
    text: "homepage_benefits_3_text",
  },
  {
    icon: <PieChart size={40} />,
    title: "homepage_benefits_4_title",
    text: "homepage_benefits_4_text",
  },
]

export default Benefits

const Wrapper = styled.div`
  padding: 3rem 0;
  background-color: #fefefe;

  h2 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .description {
    max-width: 500px;
  }

  .item {
    padding: 1rem;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    text-align: left;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    background-color: #fbe8d2;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    svg {
      color: #ff8500;
    }
  }

  .image img {
    width: 100%;
    max-width: 500px;
    border-radius: 20px;
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.3rem;
  }

  @media (max-width: 768px) {
    padding: 3rem 1rem 1rem;

    h2 {
      font-size: 1.5rem;
    }

    .description {
      font-size: 1rem;
    }

    .item {
      padding: 0.8rem;
      h4 {
        font-size: 1rem;
      }
    }

    .icon {
      width: 40px;
      height: 40px;
    }
  }
`
