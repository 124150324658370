import React from "react"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import styled from "styled-components"

const images = [
  "/pages/homepage/banner/carousel/image-1.png",
  "/pages/homepage/banner/carousel/image-2.png",
  "/pages/homepage/banner/carousel/image-4.png",
  "/pages/homepage/banner/carousel/image-7.png",
  "/pages/homepage/banner/carousel/image-5.png",
  "/pages/homepage/banner/carousel/image-6.png",
  "/pages/homepage/banner/carousel/image-8.png",
]

// Duplicate images to help create a seamless continuous effect
const duplicatedImages = [...images, ...images]

const Carousel = () => {
  const [emblaRef] = useEmblaCarousel({
    containScroll: "trimSnaps",
    loop: true,
    skipSnaps: true, // Disable snapping for free scrolling
    dragFree: true, // Allow free, momentum-based movement
    align: "center",
  })

  return (
    <Embla>
      <Viewport className="embla__viewport" ref={emblaRef}>
        <Container className="embla__container">
          {duplicatedImages.map((src, index) => (
            <SlideItem key={index} className="embla__slide">
              <img
                src={src}
                alt={`Slide ${index + 1}`}
                className="object-cover"
              />
            </SlideItem>
          ))}
        </Container>
      </Viewport>
    </Embla>
  )
}

export default Carousel

const Embla = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`

const Viewport = styled.div`
  overflow: hidden;

  @media (max-width: 768px) {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  @media (max-width: 600px) {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  @media (max-width: 500px) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
`

const Container = styled.div`
  display: flex;
`

const SlideItem = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: 0.7rem 0.5rem;

  img {
    border-radius: 15px;
    max-width: 180px;
    height: 100%;
    max-height: 170px;
  }
`
