import translate from "@/utils/translation"
import { Avatar } from "@mui/material"
import React from "react"
import { Linkedin } from "react-bootstrap-icons"
import styled from "styled-components"

const Duediligence = () => {
  return (
    <Wrapper className="container px-3 px-md-4">
      <div className="row px-1 px-md-0">
        <div className="col-12 px-2">
          <div className="d-flex notition">
            <Avatar
              src="/pages/about/employees/tom-2.jpg"
              alt="employee"
              className="ms-auto me-auto"
              sx={{ width: 80, height: 80 }}
            />
            <div className="ms-3 ms-md-4">
              <p>{translate("homepage_sourcing_quote")}</p>
              <div className="d-flex name">
                <h5>Tom van der Laan</h5>
                <p>, Co-founder</p>
                <a
                  className="ms-2 clickable"
                  style={{ marginTop: "-0.15rem", color: "black" }}
                  href="https://www.linkedin.com/in/tvanderlaan/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Duediligence

const Wrapper = styled.div`
  padding: 0rem 0 4rem;

  .notition {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    p {
      font-size: 1rem;
      font-weight: 300;
      color: rgb(23, 35, 59);
      margin-bottom: 0.5rem;
      font-style: italic;
    }

    h5 {
      font-size: 1rem;
      font-weight: 600;
    }

    .name {
      font-size: 1rem;
      font-weight: 500;

      svg {
        margin-top: -0.15rem;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        color: #17233b;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0rem 0 2rem;

    .notition {
      flex-direction: column;
      text-align: center;

      p {
        margin-top: 1rem;
        font-size: 0.9rem;
      }

      .name {
        justify-content: center;

        h5 {
          font-size: 0.9rem;
        }

        p {
          margin-top: -0.1rem;
          font-size: 0.85rem;
        }

        svg {
          margin-top: -0.3rem;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .notition {
      p {
        font-size: 0.875rem;
      }

      .name {
        h5 {
          font-size: 0.85rem;
        }
      }
    }
  }
`
